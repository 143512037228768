import * as types from "../../constants/misc/settings_constant";

const initialState = {
  data: null,
  dataError: null,
  message: null,
};

const settingsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_MISC_SETTINGS_SUCCESS:
    case types.AI_TRAINING_SUCCESS:
    case types.HONORING_SUCCESS:
      return { ...state, data: payload ? payload : null };

    case types.UPDATE_MISC_SETTINGS_SUCCESS:
      return { ...state, message: "Update successful" };

    case types.GET_MISC_SETTINGS_FAILURE:
    case types.UPDATE_MISC_SETTINGS_FAILURE:
    case types.HONORING_FAILURE:
      return { ...state, dataError: payload ? payload : null };

    default:
      return state;
  }
};

export default settingsReducer;
