export const GET_MISC_SETTINGS_SUCCESS = "GET_MISC_SETTINGS_SUCCESS";
export const GET_MISC_SETTINGS_FAILURE = "GET_MISC_SETTINGS_FAILURE";

export const UPDATE_MISC_SETTINGS_SUCCESS = "UPDATE_MISC_SETTINGS_SUCCESS";
export const UPDATE_MISC_SETTINGS_FAILURE = "UPDATE_MISC_SETTINGS_FAILURE";

export const AI_TRAINING_SUCCESS = "AI_TRAINING_SUCCESS";
export const AI_TRAINING_FAILURE = "AI_TRAINING_FAILURE";

export const HONORING_SUCCESS = "HONORING_SUCCESS";
export const HONORING_FAILURE = "HONORING_FAILURE";
