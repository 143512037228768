import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { honoringAction } from "../../redux/actions/misc/settings_action";
import AlertMessage from "../../common/actions/AlertMessage";
import Loader from "../../common/loaders/Loader";
import PageTitle from "../../common/shared/PageTitle";
import formatNumber from "../../utils/formatNumber";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import DownloadButton from "../../common/buttons/DownloadButton";
import saveDownload from "../../utils/downloadFile";
import DataTable from "../../common/shared/DataTable";

function Honor() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { data, dataError } = useSelector((state) => state.settings);

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "bar_number", headerName: "Bar Number", width: 100 },
    { field: "chamber", headerName: "Chamber", flex: 1 },
  ];

  useEffect(() => {
    dispatch(honoringAction()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <>
      {dataError && <AlertMessage type="error" message={dataError} />}{" "}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between p-4">
            <div className="flex items-center">
              <PageTitle
                title="Honoring Seniors"
                subheading={formatNumber(data.users.length)}
                icon={<Cog6ToothIcon />}
              />
            </div>

            <div className="flex items-center gap-4">
              <DownloadButton
                onClick={() => saveDownload(data.users, "honoring")}
              />
            </div>
          </div>

          <DataTable rows={data.users} columns={columns} />
        </>
      )}
    </>
  );
}

export default Honor;
