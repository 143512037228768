export const LOGIN_API = "/auth/login";
export const REGISTER_API = "/auth/register";
export const REGISTER_CHECK = "/auth/check";
export const GET_PROFILE = "/auth/profile";
export const UPDATE_PROFILE = "/auth/update/profile";
export const CHANGE_PASSWORD = "/auth/update/password";

export const GET_WHITE_LIST = "/dashboard/lawyer/whitelist/all";
export const GET_WHITE_LIST_PAG = "/dashboard/lawyer/whitelist";
export const GET_FILTER_WHITE_LIST = "/dashboard/lawyer/whitelist/filter";
export const UPLOAD_WHITE_LIST = "/dashboard/lawyer/whitelist/upload";
export const UPDATE_WHITE_LIST = "/dashboard/lawyer/whitelist/update";
export const ADD_WHITE_LIST = "/dashboard/lawyer/whitelist";

export const GET_BLACK_LIST = "/dashboard/lawyer/blacklist";
export const ADD_BLACK_LIST = "/dashboard/lawyer/blacklist";
export const UPDATE_BLACK_LIST = "/dashboard/lawyer/blacklist/update";
export const DELETE_BLACK_LIST = "/dashboard/lawyer/blacklist/delete";

export const GET_DUES_EXEMPT = "/dashboard/lawyer/dues";
export const ADD_DUES_EXEMPT = "/dashboard/lawyer/dues";
export const DELETE_DUES_EXEMPT = "/dashboard/lawyer/dues/single";
export const UPLOAD_DUES_EXEMPT = "/dashboard/lawyer/dues/upload";

export const GET_DEPT_DUES_EXEMPT = "/dashboard/lawyer/dept/all";
export const ADD_DEPT_DUES_EXEMPT = "/dashboard/lawyer/dept";
export const DELETE_DEPT_DUES_EXEMPT = "/dashboard/lawyer/dept/single";
export const UPLOAD_DEPT_DUES_EXEMPT = "/dashboard/lawyer/dept/upload";
export const UPDATE_DEPT_DUES_EXEMPT = "/dashboard/lawyer/dept/single";

export const GET_MANUAL_GREEN_LIST = "/dashboard/lawyer/manual/all";
export const ADD_MANUAL_GREEN_LIST = "/dashboard/lawyer/manual";
export const UPDATE_MANUAL_GREEN_LIST = "/dashboard/lawyer/manual/single";
export const DELETE_MANUAL_GREEN_LIST = "/dashboard/lawyer/manual/single";
export const UPLOAD_MANUAL_GREEN_LIST = "/dashboard/lawyer/manual/upload";

export const GET_BARRED_LIST = "/dashboard/lawyer/barred";
export const ADD_BARRED_LIST = "/dashboard/lawyer/barred";
export const UPDATE_BARRED_LIST = "/dashboard/lawyer/barred/single";
export const DELETE_BARRED_LIST = "/dashboard/lawyer/barred/single";

export const GET_GLC = "/dashboard/lawyer/glc";
export const ADD_GLC = "/dashboard/lawyer/glc";
export const DELETE_GLC = "/dashboard/lawyer/glc";

export const GET_LAWYERS = "/dashboard/um/users/all";
export const UPDATE_LAWYERS = "/dashboard/um/users/single";
export const DELETE_LAWYERS = "/dashboard/um/users/single";
export const RESET_PASSWORD_LAWYERS = "/dashboard/um/users/single";
export const FIRST_LAWYERS = "/dashboard/um/users";
export const FILTER_LAWYERS = "/dashboard/um/users/filter";
export const UPDATE_USER_SETTINGS = "/dashboard/um/users/settings";

export const GET_PUPILS = "/dashboard/um/pupils/all";
export const UPDATE_PUPILS = "/dashboard/um/pupils/single";
export const DELETE_PUPILS = "/dashboard/um/pupils/single";
export const FIRST_PUPILS = "/dashboard/um/pupils";
export const FILTER_PUPILS = "/dashboard/um/pupils/filter";

export const GET_ACTIVE_CHAMBER = "/dashboard/chamber/active";
export const GET_PENDING_CHAMBER = "/dashboard/chamber/pending";
export const GET_INACTIVE_CHAMBER = "/dashboard/chamber/inactive";
export const DELETE_CHAMBER = "/dashboard/chamber/delete";
export const UPDATE_CHAMBER_HEAD = "/dashboard/chamber/update";
export const SINGLE_CHAMBER_DETAILS = "/dashboard/chamber/single";
export const GET_CHAMBER_MEMBERS = "/dashboard/chamber/member";
export const ADD_CHAMBER_MEMBERS = "/dashboard/chamber/member";
export const REMOVE_CHAMBER_MEMBERS = "/dashboard/chamber/member";
export const FIRST_ACTIVE_CHAMBER = "/dashboard/chamber/first/active";
export const FIRST_INACTIVE_CHAMBER = "/dashboard/chamber/first/inactive";
export const FILTER_CHAMBER = "/dashboard/chamber/filter";
export const CHANGE_STATUS = "/dashboard/chamber/status";

export const GET_CONFERENCES = "/dashboard/conference";
export const ADD_CONFERENCE = "/dashboard/conference";
export const UPDATE_CONFERENCE = "/dashboard/conference/single";
export const CONF_PARTICIPANTS = "/dashboard/conference/single";
export const DELETE_CONFERENCE = "/dashboard/conference/single";
export const MANUAL_CONFERENCE = "/dashboard/conference/manual";
export const CONFERENCE_PAID = "/dashboard/conference/details/paid";
export const CONFERENCE_REGISTER = "/dashboard/conference/details/register";
export const CONFERENCE_NEEDS = "/dashboard/conference/needs";
export const PARTICIPANT_DELETE = "/dashboard/conference/part";
export const CONFERENCE_FILTER = "/dashboard/conference/filter";

export const GET_CONFERENCE_EXEMPT = "/dashboard/conference/exempt";
export const ADD_CONFERENCE_EXEMPT = "/dashboard/conference/exempt";
export const DELETE_CONFERENCE_EXEMPT = "/dashboard/conference/exempt/single";

export const GET_DUES = "/dashboard/payment/dues";
export const ADD_DUES = "/dashboard/payment/dues";
export const GET_DUES_DETAILS = "/dashboard/payment/dues/single";
export const UPDATE_DUES = "/dashboard/payment/dues/single";
export const MANUAL_DUES = "/dashboard/payment/dues/manual";
export const DELETE_DUES = "/dashboard/payment/dues/delete";
export const FIRST_DUES = "/dashboard/payment/dues/first";
export const FILTER_DUES = "/dashboard/payment/dues/filter";

export const GET_LICENSE = "/dashboard/payment/license";
export const GET_LICENSE_DETAILS = "/dashboard/payment/license/single";
export const ADD_LICENSE = "/dashboard/payment/license";
export const UPDATE_LICENSE = "/dashboard/payment/license/single";
export const MANUAL_LICENSE = "/dashboard/payment/license/manual";
export const DELETE_LICENSE = "/dashboard/payment/license/delete";
export const FIRST_LICENSE = "/dashboard/payment/license/first";
export const FILTER_LICENSE = "/dashboard/payment/license/filter";

export const GET_CHAMBER = "/dashboard/payment/chamber";
export const GET_CHAMBER_DETAILS = "/dashboard/payment/chamber/single";
export const ADD_CHAMBER = "/dashboard/payment/chamber";
export const UPDATE_CHAMBER = "/dashboard/payment/chamber/single";
export const MANUAL_CHAMBER = "/dashboard/payment/chamber/manual";
export const DELETE_PAYMENT_CHAMBER = "/dashboard/payment/chamber/delete";
export const FIRST_CHAMBER_PAY = "/dashboard/payment/chamber/first";
export const FILTER_CHAMBER_PAY = "/dashboard/payment/chamber/filter";

export const GET_PUPILAGE = "/dashboard/payment/pupilage";
export const GET_PUPILAGE_DETAILS = "/dashboard/payment/pupilage/single";
export const ADD_PUPILAGE = "/dashboard/payment/pupilage";
export const UPDATE_PUPILAGE = "/dashboard/payment/pupilage/single";
export const DELETE_PUPILAGE = "/dashboard/payment/pupilage/delete";
export const MANUAL_PUPILAGE = "/dashboard/payment/pupilage/manual";
export const FIRST_PUPILAGE = "/dashboard/payment/pupilage/first";
export const FILTER_PUPILAGE = "/dashboard/payment/pupilage/filter";

export const GET_STICKERS = "/dashboard/payment/sticker";
export const MANUAL_STICKERS = "/dashboard/payment/sticker";

export const PENDING_STICKERS = "/dashboard/stickers/pending";
export const APPROVED_STICKERS = "/dashboard/stickers/approved";
export const ALL_STICKERS = "/dashboard/stickers/all";
export const UPDATE_STICKERS = "/dashboard/stickers/update";

export const GOOD_STANDING = "/dashboard/um/users/standing";
export const STANDING_PRODUCT_YEAR = "/dashboard/um/users/standing";
export const NO_STANDING = "/dashboard/um/users/last/five";
export const FILTER_STANDING = "/dashboard/um/users/stand/filter";

export const BOOTCAMP_LIST = "/dashboard/bootcamp";
export const BOOTCAMP_SINGLE = "/dashboard/bootcamp/single";
export const BOOTCAMP_MANUAL = "/dashboard/bootcamp/manual";

export const MISC_SETTINGS = "/dashboard/misc/settings";
export const MISC_SETTINGS_UPDATE = "/dashboard/misc/settings/single/update";

export const AI_TRAINING = "/dashboard/misc/training";
export const TRAINING_LIST = "/dashboard/payment/training";
export const TRAINING_LIST_DETAILS = "/dashboard/payment/training/single";

export const HONORING = "/dashboard/misc/talk";
